import React from "react";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout>
      <div className="content">
        <p>Svenska's Culinary is an out-of-home traditional artisan bakery business based locally in Charleston, South Carolina.</p>
        <p>We use a variety of traditional techniques and family recipes to serve some of the finest in American-Swedish fusion baked goods!</p>
        <p>We pride ourselves on our fresh, preservative-free bakes.</p>
      </div>
    </Layout>
  )
}

export default IndexPage
